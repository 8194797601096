/* @jsx jsx */

import { jsx, Box } from 'theme-ui'
import FullStarIcon from '~/assets/images/icons/full-star.svg'
import EmptyStarIcon from '~/assets/images/icons/empty-star.svg'

const PartialStar = ({ decimal = 0.5, color }) => {
  const clipPathValue = (1 - decimal) * 100
  return (
    <Box
      sx={{
        '--star-size': '1.25rem',
        position: 'relative',
        height: 'var(--star-size)',
        width: 'var(--star-size)',
      }}
    >
      <FullStarIcon
        sx={{
          position: 'absolute',
          inset: 0,
          overflow: 'hidden',
          clipPath: `inset(0 ${clipPathValue}% 0 0)`,
          path: { stroke: color, fill: color },
        }}
      />
      <EmptyStarIcon
        sx={{ path: { stroke: color }, position: 'absolute', inset: 0 }}
      />
    </Box>
  )
}

export default PartialStar
