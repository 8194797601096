/* @jsx jsx */
import { jsx, Grid, Flex, Text, Link } from 'theme-ui'
import { useEffect, useState } from 'react'
import { getProductReviews } from '~/utils/yotpo/get-product-reviews'
import FullStarIcon from '~/assets/images/icons/full-star.svg'
import EmptyStarIcon from '~/assets/images/icons/empty-star.svg'
import PartialStar from './PartialStar'

const StarRating = ({
  product,
  forwardSx = {},
  color,
  showTotalReviews = true,
  showReadReviews = false,
}) => {
  const [productReviews, setProductReviews] = useState({
    averageScore: null,
    totalReviews: null,
  })

  const totalStars = 5

  useEffect(() => {
    getProductReviews({ slug: product.slug }).then(data => {
      setProductReviews({
        averageScore: data.average_score,
        totalReviews: data.total_reviews,
      })
    })
  }, [product])

  if (!productReviews.totalReviews || !productReviews.averageScore) return null

  const { averageScore, totalReviews } = productReviews

  const starsArray = Array.from({ length: totalStars }, (_, index) => {
    if (index + 1 <= averageScore)
      return <FullStarIcon sx={{ path: { fill: color, stroke: color } }} />
    if (averageScore % 1 !== 0)
      return (
        <PartialStar color={color} decimal={(averageScore % 1).toFixed(1)} />
      )
    if (index + 1 > averageScore)
      return <EmptyStarIcon sx={{ path: { stroke: color } }} />
  })

  return (
    <Grid
      sx={{
        justifyItems: 'center',
        gridAutoFlow: showReadReviews && !showTotalReviews ? 'column' : 'row',
        gridGap: '0.625rem',
        ...forwardSx,
      }}
    >
      <Flex sx={{ alignItems: 'center', gap: '0.625rem' }}>
        <Flex sx={{ gap: '0.5rem' }}>{starsArray}</Flex>
        <Text
          as="span"
          sx={{
            fontFamily: 'secondary',
            fontWeight: 'regular',
            lineHeight: 0.88,
          }}
        >
          {averageScore.toFixed(1)}
        </Text>
      </Flex>
      {showReadReviews && (
        <Link
          href="#review-widget"
          sx={{
            borderBottom: '2px solid',
            textTransform: 'uppercase',
            fontFamily: 'secondary',
            fontSize: '0.875rem',
          }}
        >
          {totalReviews} Reviews
        </Link>
      )}
      {showTotalReviews && (
        <Text
          as="span"
          sx={{
            fontFamily: 'secondary',
            fontWeight: 'regular',
            lineHeight: 0.88,
          }}
        >
          {totalReviews} reviews
        </Text>
      )}
    </Grid>
  )
}

export default StarRating
