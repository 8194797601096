import useSWR from 'swr'
import axios from 'axios'

const API_URL = process.env.GATSBY_ACS_API_URL || ''

const fetcher = url => axios.get(url).then(res => res.data)

const useVariantAvailability = ({ sku }) => {
  const baseURL = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL

  // filter by channel if channel is set in env variables.
  const channelFilter = process.env.GATSBY_CHORD_OMS_DEFAULT_CHANNEL

  const query = `${baseURL}/api/variants/${sku}/stock_availabilities${
    channelFilter ? '?channel=' + channelFilter : ''
  }`

  const { data, error } = useSWR(query, fetcher)

  // Start by assuming that the product is available. And if something
  // wrong happened in this hook, worse case scenario, it will be handled
  // by Chord's checkout.
  return {
    isAvailable: data ? data.in_stock || data.is_backorderable : true,
    isFetchingAvailability: !error && !data,
    error: error
  }
}

export default useVariantAvailability
