export const getProductReviews = async ({ slug }) => {
  const fetchUrl = `https://api.yotpo.com/products/${process.env.GATSBY_YOTPO_APP_KEY}/${slug}/bottomline`

  try {
    const data = await fetch(fetchUrl).then(response => response.json())
    const response = data?.response?.bottomline || {}
    return response
  } catch (error) {
    console.error('Could not fetch product reviews: ', error)
  }
}
